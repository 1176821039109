<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :before-close="cancel" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="640px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="84px" v-if="form">
      <el-form-item label="营业执照" prop="licenseCode">
        <el-input v-model="form.licenseCode" maxlength="20" @keypress.enter.native="findByLicenseCode">
          <el-button slot="append" icon="el-icon-search" v-loading="searching" @click="findByLicenseCode"></el-button>
        </el-input>
      </el-form-item>
      <div class="h">
        <el-form-item label="企业名称" prop="name" class="flex">
          <el-input v-model="form.name" maxlength="20" />
        </el-form-item>
        <el-form-item label="企业类型" prop="enterpriseType" label-width="108px" style="width: 264px;">
          <quick-select v-model="form.enterpriseType" filterable url="api/dictDetail" display-field="label" :params="{dictName: 'enterprise_type'}" placeholder="企业类型" style="width: 100%;" />
        </el-form-item>
      </div>
      <div class="h">
        <el-form-item class="flex" label="所在地区" prop="provinceCode">
          <quick-select v-model="form.provinceCode" :label.sync="form.provinceName" url="api/regionalDict/province" value-field="code" placeholder="选择省份" filterable style="width: 100%;" @change="form.cityCode = null; form.districtCode = null;" />
        </el-form-item>
        <el-form-item label-width="12px" prop="cityCode" style="width: 180px;">
          <quick-select v-model="form.cityCode" :label.sync="form.cityName" :url="`api/regionalDict/parent/${form.provinceCode}`" value-field="code" placeholder="选择城市" filterable v-if="form.provinceCode" style="width: 100%;" @change="form.districtCode = null" />
          <el-input disabled placeholder="请先选择省份" v-else />
        </el-form-item>
        <el-form-item label-width="12px" prop="districtCode" style="width: 180px;">
          <quick-select v-model="form.districtCode" :label.sync="form.districtName" :url="`api/regionalDict/parent/${form.cityCode}`" value-field="code" placeholder="选择地区" filterable v-if="form.cityCode" style="width: 100%;" />
          <el-input disabled placeholder="请先选择城市" v-else />
        </el-form-item>
      </div>

      <el-form-item label="详细地址">
        <el-input v-model="form.address" maxlength="100" />
      </el-form-item>

      <el-form-item label="经营范围">
        <el-input type="textarea" v-model="form.businessScope" :rows="3" resize="none" maxlength="250" />
      </el-form-item>

      <el-form-item label="企业简介">
        <el-input type="textarea" v-model="form.enterpriseInfo" :rows="5" resize="none" maxlength="250" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addSub, edit, getByLicenseCode } from "@/api/sysEnterprise";
import { parseTime } from "@/utils/index";
import initDict from "@/mixins/initDict";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  mixins: [initDict],
  data() {
    return {
      loading: false,
      searching: false,
      dialog: false,
      form: {
        id: -1,
        licenseCode: "",
        name: "",
        provinceCode: "",
        cityCode: "",
        districtCode: "",
        provinceName: "",
        cityName: "",
        districtName: "",
        address: "",
        enterpriseType: "",
        businessScope: "",
        enterpriseInfo: "",
        createAt: null,
        createBy: "",
        editAt: null,
        editBy: "",
        status: 0
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        licenseCode: [
          { required: true, message: "请输入企业代码", trigger: "blur" }
        ],
        provinceCode: [{ required: true, message: "请选择省份" }],
        cityCode: [{ required: true, message: "请选择城市" }],
        districtCode: [{ required: true, message: "请选择地区" }],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        enterpriseType: [
          { required: true, message: "请选择类型", trigger: "blur" }
        ]
      }
    };
  },

  created() {
    this.$nextTick(() => {
      // 加载数据字典
      this.getDict("enterprise_type");
    });
  },
  methods: {
    parseTime,
    cancel() {
      this.resetForm();
    },
    doSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }
      });
    },
    doAdd() {
      addSub(this.form)
        .then(res => {
          this.resetForm();
          this.$notify({
            title: "添加企业信息成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
        });
    },
    doEdit() {
      edit(this.form)
        .then(res => {
          this.resetForm();
          this.$notify({
            title: "修改企业信息成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
        });
    },
    findByLicenseCode() {
      if (this.form.licenseCode) {
        this.searching = true;
        getByLicenseCode(this.form.licenseCode)
          .then(res => {
            if (res) {
              Object.assign(this.form, res);
            }
            this.searching = false;
          })
          .catch(err => {
            this.searching = false;
          });
      }
    },
    resetForm() {
      this.dialog = false;
      this.$refs["form"].resetFields();
      this.form = {
        id: "",
        licenseCode: "",
        name: "",
        provinceCode: "",
        cityCode: "",
        districtCode: "",
        provinceName: "",
        cityName: "",
        districtName: "",
        address: "",
        enterpriseType: "",
        businessScope: "",
        enterpriseInfo: "",
        createAt: "",
        createBy: "",
        editAt: "",
        editBy: "",
        status: ""
      };
    }
  }
};
</script>