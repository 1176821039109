<template>
  <div class="app-container">
    <!--工具栏-->
    <div class="head-container">
      <el-input v-model.trim="keyword" :maxlenght="10" class="filter-item" prefix-icon="el-icon-search" clearable placeholder="输入企业名称搜索" style="width: 240px;" />
      <div style="display: inline-block;margin: 0px 2px;">
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增子公司</el-button>
      </div>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <!--表单组件-->
    <eForm ref="form" :is-add="isAdd" />
    <account-form ref="account" :is-add="isAdd" />
    <!--表格渲染-->
    <el-table v-loading="loading" row-key="id" :data="filteredData">
      <el-table-column prop="licenseCode" label="营业执照号" min-width="150" />
      <el-table-column prop="name" label="企业名称" min-width="160" />
      <el-table-column prop="provinceName" label="省份" min-width="120" />
      <el-table-column prop="cityName" label="城市" min-width="120" />
      <el-table-column prop="address" label="详细地址" show-overflow-tooltip min-width="240" />
      <el-table-column prop="createAt" label="创建时间" width="160" :formatter="r => {return new Date(r.createAt).format('yyyy/MM/dd hh:mm');}" />
      <el-table-column width="150px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button size="mini" type="text" @click="account(scope.row)">账号</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="text" class="danger" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { get } from "@/api/user";
import { del } from "@/api/sysEnterprise";
import eForm from "./form";
import { mapGetters } from "vuex";
import accountForm from "@/views/system/user/form";

export default {
  components: { eForm, accountForm },
  mixins: [initData],
  data() {
    return {
      keyword: "",
      delLoading: false,
    };
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  computed: {
    ...mapGetters(["sidebar", "user", "device", "baseApi"]),
    filteredData() {
      if (this.keyword) {
        return (this.data || []).filter((o) => {
          return (o.name || "").indexOf(this.keyword) >= 0;
        });
      } else {
        return this.data;
      }
    },
  },

  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/enterprisesub/list";
      this.params = { sort: "createAt,desc" };
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    add() {
      this.isAdd = true;
      var _this = this.$refs.form;
      _this.dialog = true;
      _this.form.createAt = new Date().getTime();
      _this.form.createBy = this.user.userRealName;
    },
    edit(data) {
      this.isAdd = false;
      const _this = this.$refs.form;
      _this.form = {
        id: data.id,
        licenseCode: data.licenseCode,
        name: data.name,
        provinceCode: data.provinceCode,
        cityCode: data.cityCode,
        districtCode: data.districtCode,
        provinceName: data.provinceName,
        cityName: data.cityName,
        districtName: data.districtName,
        address: data.address,
        enterpriseType: data.enterpriseType,
        businessScope: data.businessScope,
        enterpriseInfo: data.enterpriseInfo,
        createAt: data.createAt,
        createBy: data.createBy,
        editAt: new Date().getTime(),
        editBy: this.user.userRealName,
        status: 0,
      };
      _this.dialog = true;
    },
    account(data) {
      get({
        size: 1,
        page: 0,
        enterpriseId: data.id,
      }).then((res) => {
        if (res && res.content && res.content.length) {
          this.isAdd = false;
          let d = res.content[0];
          d._roleIds = d.roles.map((r) => {
            return r.id;
          });
          this.$refs.account &&
            this.$refs.account.resetForm(res.content[0], data.id);
        } else {
          this.isAdd = true;
          this.$refs.account &&
            this.$refs.account.resetForm(null, data.id, {
              enterpriseId: data.id,
            });
        }
      });
    },
  },
};
</script>